import React, { useState } from "react";
import { withTranslation } from "react-i18next"; 
import Summary from "./Summary";
import LiveAndDemoClients from "./LiveAndDemoClients";
import RecentDeposits from "./RecentDeposits";
import RecentWithdrawals from "./RecentWithdrawals";
import IBJourney from "components/Journey/IBJourney";
import { useSelector } from "react-redux";
import IBReport from "./IBReport";

const IbPortalDashboard = () => {
  const { ibMT5Acc = [], ibCTRADERAcc = [] } = useSelector((state) => state.Profile.clientData.fx);
  const [selectedPlatform, setSelectedPlatform] = useState({
    label: `MT5 ${ibMT5Acc.length > 0 ? ` (${ibMT5Acc[0]})` : ""}`,
    value: "MT5",
  }, {
    label: `CTRADER ${ibCTRADERAcc.length > 0 ? ` (${ibCTRADERAcc[0]})` : ""}`,
    value: "CTRADER",
  });
  return (
    <div style={{
      marginBottom: "10%",
    }}>
      <IBJourney></IBJourney>
      {/* summary */}
      <div className="pt-3">
        <Summary selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />
      </div>
      <div>
        <IBReport />
      </div>
      {/* live and demo accounts */}
      <div className="pt-3">
        <LiveAndDemoClients selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />
      </div>
      {/* recent deposits */}
      <div className="pt-3">
        <RecentDeposits selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />
      </div>
      {/* recent withdrawals */}
      <div className="pt-3">
        <RecentWithdrawals selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />
      </div>
    </div>
  );
};

export default withTranslation()(IbPortalDashboard);