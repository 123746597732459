import { useState, useEffect } from "react";
import { 
  Button, Col, Row, Spinner 
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import {
  addDeposit,
  addForexDeposit,
  paymentGatewayDeposit,
} from "apis/deposit";
import { allowedMethods, allowedMethodsLocal } from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import A3tmad from "./Methods/A3tmad";
import Praxis from "./Methods/Praxis";

function Deposit({ isOpen, toggleOpen, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});
  const [amount, setAmount] = useState();

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [praxisName, setPraxisName] = useState("");

  // Effects
  useEffect(() => {
    console.log({ allowedMethods });
  }, [allowedMethods]);
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(
        getAccountsStart({
          type: "LIVE",
        })
      );
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
      case "PAYEER":
      case "حواله بنكيه":
      case "WESTERN_UNION":
        return (
          <WireTransfer
            t={t}
            setIsFirstStepValid={setIsFirstStepValid}
            gateway={gateway}
          />
        );
      case "OLX_FOREX":
        return <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} />;
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />;
      case "CRYPTO":
        return (
          <CompanyCrypto
            t={t}
            setIsFirstStepValid={setIsFirstStepValid}
            setPaymentPayload={setPaymentPayload}
          />
        );
      // case "صناديق-الاعتماد":
      //   return (
      //     <A3tmad t={t} setIsFirstStepValid={setIsFirstStepValid} />
      //   );
      case "PRAXIS_PayMaxis":
      case "PRAXIS_Brightcart":
      case "PRAXIS_Voucherry":
      case "PRAXIS_AsiaBanks":
      case "PRAXIS_Coinspaid":
      case "PRAXIS_PayMaxisAPM":
      case "PRAXIS_PayMaxis_GATEWAY":
      case "PRAXIS_B2Broker":
      case "PRAXIS_PayMaxisAPM_GATEWAY":
        return (
          <Praxis
            t={t}
            setIsFirstStepValid={setIsFirstStepValid}
            setPaymentPayload={setPaymentPayload}
            praxisName={praxisName}
          />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">{t("Please Select Payment Method")}</h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
    if (type === "fiatDeposit") {
      if (selectedPaymentMethod.includes("PRAXIS")) {
        data.gateway = "PRAXIS";
        data.amount = amount;
        data.paymentPayload = paymentPayload;
        paymentGatewayDeposit(data, "PRAXIS")
          .then((res) => {
            setResult(res);
            toggleTab(2);
            if ((res.result && res.result.redirect_url) || !res.isError) {
              window.location.href = res.result.redirect_url;
            } else {
              throw new Error(res.message);
            }
          })
          .catch((err) => {
            setResult(err);
            toggleTab(2);
          });
      } else if (selectedPaymentMethod === "OLX_FOREX") {
        paymentGatewayDeposit(data, selectedPaymentMethod)
          .then((res) => {
            setResult(res);
            toggleTab(2);
            console.log(res);
            window.location.href =
              selectedPaymentMethod === "PRAXIS"
                ? res.result.redirect_url
                : res.result.url;
          })
          .catch((err) => {
            setResult(err);
            toggleTab(2);
          });
      } else {
        console.log("Dataaaaa", data);
        addDeposit(data)
          .then((res) => {
            setLoading(false);
            setResult(res);
            toggleTab(2);
          })
          .catch((err) => {
            setLoading(false);
            setResult(err);
            toggleTab(2);
          });
      }
    } else if (type === "mt5Deposit") {
      if (selectedPaymentMethod === "PRAXIS") {
        paymentGatewayDeposit(data, selectedPaymentMethod)
          .then((res) => {
            setResult(res);
            toggleTab(2);
            window.location.href = res.result.url;
          })
          .catch((err) => {
            setResult(err);
            toggleTab(2);
          });
      } else {
        addForexDeposit(data)
          .then((res) => {
            setResult(res);
            toggleTab(2);
          })
          .catch((err) => {
            setResult(err);
            toggleTab(2);
          });
      }
    }
  };

  // Function to find the receipt array for a given gateway
const findReceiptForGateway = (gateway) => {
  // Search in allowedMethods
  const methodInAllowedMethods = allowedMethods.find((method) => method.gateway === gateway);

  // Search in allowedMethodsLocal
  const methodInAllowedMethodsLocal = allowedMethodsLocal.find((method) => method.gateway === gateway);

  // Check if the method exists in allowedMethods and has a receipt property which is an array
  if (methodInAllowedMethods && methodInAllowedMethods.receipt && Array.isArray(methodInAllowedMethods.receipt)) {
    return methodInAllowedMethods.receipt;
  }

  // Check if the method exists in allowedMethodsLocal and has a receipt property which is an array
  if (methodInAllowedMethodsLocal && methodInAllowedMethodsLocal.receipt && Array.isArray(methodInAllowedMethodsLocal.receipt)) {
    return methodInAllowedMethodsLocal.receipt;
  }

  // Default value or handling if no valid receipt array is found in both allowedMethods and allowedMethodsLocal
  return []; // or any other default value or handling you prefer
};

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">{t("Select Payment Method")}</h6>
              <Row className="justify-content-center payment-methods">
                {allowedMethods
                  ?.filter((method) => method?.allowed?.includes(type))
                  .map((method) => (
                    <Col key={method.gateway} xs={4} lg={2} className="my-2">
                      <button
                        type="button"
                        onClick={() => {
                          setGateway(method.gateway);
                          setSelectedPaymentMethod(method.gateway);
                          setPraxisName(method.name);
                        }}
                        onMouseEnter={() => {
                          setHoveredPaymentMethod(method.gateway);
                        }}
                        onMouseLeave={() => {
                          setHoveredPaymentMethod();
                        }}
                        className={`btn btn-${
                          selectedPaymentMethod === method.gateway
                            ? "success"
                            : hoveredPaymentMethod === method.gateway
                              ? "default"
                              : "light"
                        } waves-effect waves-light w-sm py-4 d-flex align-items-center justify-content-center`}
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                        }}
                      >
                        <img
                          src={method.image}
                          width="100%"
                          height="100%"
                          alt=""
                        ></img>
                      </button>
                    </Col>
                  ))}
              </Row>
              <Row className="mt-5 payment-methods">
                <h6 className="mb-3">{t("Local Deposit Methods")}</h6>
                {allowedMethodsLocal
                  ?.filter((method) => method?.allowed?.includes(type))
                  .map((method) => (
                    <Col key={method.gateway} xs={4} lg={2} className="my-2">
                      <button
                        type="button"
                        onClick={() => {
                          setGateway(method.gateway);
                          setSelectedPaymentMethod(method.gateway);
                          setPraxisName(method.name);
                        }}
                        onMouseEnter={() => {
                          setHoveredPaymentMethod(method.gateway);
                        }}
                        onMouseLeave={() => {
                          setHoveredPaymentMethod();
                        }}
                        className={`btn btn-${
                          selectedPaymentMethod === method.gateway
                            ? "success"
                            : hoveredPaymentMethod === method.gateway
                              ? "default"
                              : "light"
                        } waves-effect waves-light w-sm py-4 d-flex align-items-center justify-content-center`}
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                        }}
                      >
                        <img
                          src={method.image}
                          width="100%"
                          height="100%"
                          alt=""
                        ></img>
                      </button>
                    </Col>
                  ))}
              </Row>
            </div>
            {gateway && stageOnePaymentDetails()}
            <div className="text-center mt-4">
              {gateway === "PAYEER" ||
              gateway === "حواله بنكيه" ||
              gateway === "WESTERN_UNION" ? (
                  <Button
                    type="submit"
                    className="btn btn-success waves-effect waves-light w-lg btn-sm"
                    disabled={gateway === ""}
                    onClick={() => toggleTab(1)}
                  >
                    {t("Continue")}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn btn-success waves-effect waves-light w-lg btn-sm"
                    disabled={gateway === "" || !isFirstStepValid}
                    onClick={() => toggleTab(1)}
                  >
                    {t("Continue")}
                  </Button>
                )}
            </div>
          </div>
        </>
      ),
    },
    {
      header: t("Enter Amount"),
      content: (
        <StageTwo
          t={t}
          setLoading={setLoading}
          loading={loading}
          toggleTab={toggleTab}
          type={type}
          gateway={gateway}
          handleSubmit={submitHandler}
          paymentPayload={paymentPayload}
          receipt={findReceiptForGateway(gateway)}
          amount={amount}
          setAmount={setAmount}
        />
      ),
    },
    {
      header: "Deposit status",
      content: (
        <>
          {result.status ? (
            <>
              {gateway.includes("PRAXIS") ? (
                <>
                  <div className="text-center  mb-4">
                    <h1 className="fs-1 mb-5">
                      {t("Redirect....")} <i className="bx bx-wink-smile"></i>
                    </h1>
                  </div>
                  <CardWrapper className="mb-4">
                    <div className="d-flex align-items-center justify-content-around px-4">
                      <Spinner />
                    </div>
                  </CardWrapper>
                </>
              ) : (
                <>
                  <div className="text-center  mb-4">
                    <h1 className="fs-1 mb-5">
                      {t("Yay!")} <i className="bx bx-wink-smile"></i>
                    </h1>
                    <p>{t("Pending Deposit Thank You")}</p>
                    <p>
                      <span className="positive">
                        {type === "fiatDeposit"
                          ? result?.result?.amount?.$numberDecimal
                          : result?.result?.amount}{" "}
                        {type === "fiatDeposit"
                          ? result?.result?.currency
                          : result?.result?.currency}
                      </span>
                    </p>
                    <span className="text-muted">
                      {t("Your transaction ID is")}
                      {result?.result?._id}
                    </span>
                  </div>
                  <CardWrapper className="mb-4">
                    <div className="d-flex align-items-center justify-content-around px-4">
                      <div>
                        <div className="text-muted">{t("Status")}</div>
                        <div className="positive">
                          {t(result?.result?.status)}
                        </div>
                      </div>
                      <div>
                        <div className="text-muted">{t("GATEWAY")}</div>
                        <div>{result.result?.gateway}</div>
                      </div>
                    </div>
                  </CardWrapper>
                </>
              )}
            </>
          ) : (
            <>
              <div className="text-center  mb-4">
                <h1 className="fs-1 mb-5">
                  {t("Oops!")} <i className="bx sad"></i>
                </h1>
                <p>{t("Your Deposit Request Not Successfully Created")}</p>
              </div>
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-between px-5">
                  <div>
                    <div className="positive">{result.message}</div>
                  </div>
                </div>
              </CardWrapper>
            </>
          )}
          <div className="text-center">
            <Button
              className="btn btn-danger m-2 btn-sm w-lg"
              onClick={toggleOpen}
            >
              {t("Continue")}
            </Button>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>
  );
}
export default withTranslation()(Deposit);
