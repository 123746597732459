import React, { useEffect } from "react";
import {
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import {
  CardBody, CardTitle, Modal, Button, ModalHeader
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";
import { fetchDocsStart } from "store/general/documents/actions";

const SubmitProfile = (props) => {
  const history = useHistory();
  const docs = useSelector(state => state.documents.documents);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDocsStart());
  }, []);
  return (<React.Fragment>
    <Modal
      isOpen={props.show}
      toggle={() => props.toggle()}
      centered={true}
      //   size="lg"
      className='custom-modal'
    >
      <div style={{
        padding: 20,
      }}>
        <ModalHeader className="d-flex flex-column gap-3">
          <CloseButton
            onClick={() => props.toggle()}
            style={{ 
              alignSelf: "flex-end",
              position: "absolute", 
              right: 10,
              top: 10 
            }} 
          />
          <div className="text-center">
            <CardTitle className="mb-0">{props.t("KYC Document Rejected")}</CardTitle>
          </div>
        </ModalHeader>
        <CardBody>
          {docs.filter((doc) => doc.status === "REJECTED").map((doc, i) => (
            <h5 key={i} className="m-5 text-center">{props.t(`${doc.type} Has Been Rejected Because of ${doc?.rejectionReason}`)}</h5>

          ))}
          <div className="text-center">
            <Button onClick={()=>{
              localStorage.setItem("KYC_SKIP", true);
              props.toggle();
            }} color="danger" className="w-lg waves-effect waves-light m-2">
              {props.t("Cancel")}
            </Button>
            <Button onClick={() => { 
              if (history.location.pathname === "/documents") {
                props.toggle();
              }
              else {
                props.toggle();
                history.push("/documents");
              }
            }} color="success" className="w-lg waves-effect waves-light m-2">
              {props.t("Reupload")}
            </Button>
          </div>
        </CardBody>
      </div>
    </Modal>
  </React.Fragment>);
};

const mapStateToProps = (state) => ({
  profile: state.Profile && state.Profile.clientData || {},
});
export default connect(mapStateToProps, null)(withTranslation()(SubmitProfile)); 