import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { getIBCustomerStart, requestPartnershipChnage } from "store/actions";
import { useEffect, useState } from "react";

const ChangeIBModal = ({ isOpen, toggle, accounts, t }) => {
  const dispatch = useDispatch();
  const ibCustomers = useSelector((state) => state.forex.accounts.ibcustomers);

  const [selectedAccountId, setSelectedAccountId] = useState(accounts?.[0]?._id);
  const [selectedAccount, setSelectedAccount] = useState(accounts?.[0]);
  const [selectedIB, setSelectedIB] = useState(ibCustomers?.[0]?._id);
  const [requestedIB, setRequestedIB] = useState(ibCustomers?.[0]);
  const [errorMsg, setErrorMsg] = useState("");
  
  const handleValidSubmit = (event, values) => {
    if (selectedAccount?.[0]?.ibLink?.ibAccountId?.customerId?._id === requestedIB?.[0]?._id) {
      setErrorMsg("Can Not Change IB To The Current IB");
    } else {

      dispatch(requestPartnershipChnage({
        login: selectedAccount?.[0].login,
        ibLogin: requestedIB?.[0]?.fx?.ibMT5Acc?.[0],
        agreementId: selectedAccount?.[0]?.ibLink?.agreementId?._id,
        platform: selectedAccount?.[0]?.ibLink?.platform,
        currentIB: selectedAccount?.[0]?.ibLink?.ibAccountId?.customerId?._id,
        requestedIB: requestedIB?.[0]?._id
      }));

    }
  };
  
  useEffect(() => {
    dispatch(getIBCustomerStart());
    setSelectedAccount(accounts?.filter((account) => account._id === selectedAccountId));
    setRequestedIB(ibCustomers?.filter((account) => account._id === selectedIB));
  }, [selectedAccountId, selectedIB]);
  
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Change IB")}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <AvField type="select" name="account" label={t("Select Account")} className="form-select mt-1 mb-2" required
            onChange={(e) =>  setSelectedAccountId(e.target.value)}
          >
            <option disabled value="">-Select an Account-</option>

            {accounts?.map((account) =>
              <option key={account.login} value={account._id}>{account.login}</option>
            )};
          </AvField>
          <AvField type="select" name="ib-accounts" label={t("Select IB")} className="form-select mt-1 mb-2" required
            onChange={(e) => setSelectedIB(e.target.value)}
          >
            <option disabled value=""> -Select an IB-</option>
            
            {ibCustomers?.filter((account) => account.fx.ibMT5Acc.length > 0).map((account) =>
              <option key={account._id} value={account._id}>{account.firstName} {account.lastName}  </option>
            )};
          </AvField>
          <p style={{color : "red"}}>{errorMsg && errorMsg}</p>
          <div className="text-center mt-3 mb-1">
            <Button type="submit" className="border-0 color-bg-btn shadow">{t("Change")}</Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(ChangeIBModal);