import { AvField, AvForm } from "availity-reactstrap-validation";
import calculateFee from "helpers/calculateFee";
import { validateFile } from "helpers/validations/file";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  InputGroup,
  InputGroupText,
  Label
} from "reactstrap";
import FiatFormDetails from "./Fiat/FiatFormDetails";
import Mt5Details from "./MT5/Mt5Details";


export default function StageTwo(props) {
  const {
    t,
    toggleTab,
    type,
    gateway,
    handleSubmit,
    receipt = false,
    loading,
    paymentPayload,
    setLoading,
    amount,
    setAmount
  } = props;

  const [file, setFile] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [amountValidation, setAmountValidation] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const tranFeeGroupDetails = useSelector((state) => state.Profile?.clientData?.transactionFeeId);
  const [transactionFee, setTransactionFee] = useState(0);

  const amountValidationHandler = () => {
    amount < 15 ? setAmountValidation(true) : setAmountValidation(false);
  };

  useEffect(() => {
    setTransactionFee(tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0);
    amountValidationHandler();
  }, [tranFeeGroupDetails, amount]);

  const renderFormType = () => {
    switch (type) {
      case "fiatDeposit":
        return <FiatFormDetails t={t}
          selectedWallet={selectedWallet}
          amount={amount}
          setAmount={setAmount}
          setAmountValidation={setAmountValidation}
          setSelectedWallet={setSelectedWallet}
        />;
      case "mt5Deposit":
        return <Mt5Details
          t={t}
          amount={amount}
          setAmount={setAmount}
          amountValidation={amountValidation}
        />;
      default:
        return <></>;
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewUrl(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  };

  return (
    <AvForm
      onValidSubmit={(e, v) => {
        setLoading(true);
        const formData = new FormData();
        if (type === "mt5Deposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("tradingAccountId", v.tradingAccountId);
            formData.append("receipt", file);
            console.log(file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            if (gateway === "CRYPTO"){
              handleSubmit({
                gateway,
                paymentPayload,
                amount: parseFloat(amount),
                note: "note",
                tradingAccountId: v.tradingAccountId,
              });
            }
          }
        } else if (type === "fiatDeposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("walletId", selectedWallet.value);
            formData.append("receipt", file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload,
              amount: parseFloat(amount),
              note: "note",
              walletId: selectedWallet.value,
            });
          }
        }
      }}
    >
      {
        gateway === "WESTERN_UNION" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
            textAlign:"center"
            }}> {t("Please contact us via Live Chat")} </h2>
          <p className="my-5"> {t("Follow the instruction below 1, please contact us via direct registeration to obtain transfer information 2, After converting the currency , take a photo of the currency and attach it to the advanced search to convert 3, when depositing through the Western Union portal, the deposited capital is withdrawn exclusievely Western Union , but it can be withdrawen via any withdrawel method available on the customer portal")} </p>
        </div>
        </>
        
        : ""
      }

{
        gateway === "حواله بنكيه" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
            textAlign:"center",
          }}> {t("Please contact us via Live Chat")}  </h2>
          <p className="my-5">{t("Follow the istructions below 1. Please contact us via live chat to get the transfer information 2.After  transfering the money , take a photo the transfer receipt and attach it in the Proof of Transfer field")}</p>
        </div>
        </>
        
        : ""
      }

{
        gateway === "PAYEER" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
           textAlign:"center",
          }}> {t("Send Money to : P1059861408")}</h2>
          <p className="my-5">{t("Follow the instructions below 1. Go to the Payeer App 2. Send Money to our wallet number above 3. Click Confirm Transfer , take a screenshot and upload it to the Confirm Traansfer field")}</p>
        </div>
        </>
        
        : ""
      }
      <h6 className="mb-3">{t("Enter Amount")}</h6>
      <div className="d-flex justify-content-between mb-2">
        {renderFormType()}
      </div>
      <div className="mb-3">
        <Label className="form-label mb-2">{t("Transaction Fee")}</Label>
        <InputGroup className="">
          <InputGroupText className=" w-100">
            {parseFloat(transactionFee ?? 0)}{"   "}{selectedWallet?.asset}
          </InputGroupText>
        </InputGroup>
      </div>
      {(gateway === "حواله بنكيه" || gateway === "WESTERN_UNION" || gateway === "PAYEER") && (
        <div className="mb-3">
      <Label className="form-label" htmlFor="receipt">
        {t("Receipt")}
      </Label>
      <AvField
        type="file"
        name="receipt"
        id="receipt"
        className="form-control form-control-md"
        errorMessage={t("Receipt is required")}
        onChange={handleFileChange}
        validate={{
              required: { value: true },
              validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                sizeValidationMessage: t("The file size is too large"),
                extensionValidationMessage: t("The file extension is not allowed"),
              })
            }}
      />
      {previewUrl && (
        <div className="mt-2 text-center">
          <img src={previewUrl} alt="Preview" style={{
            maxWidth: "100%",
            maxHeight: "200px"
          }} />
        </div>
      )}
    </div>
        
      )}
  
      <div className="my-4 text-center">
        <Button
          className="btn btn-primary m-2 btn-sm w-lg"
          onClick={() => toggleTab(0)}
        >
          {t("Back")}
        </Button>
        <Button
          className="btn btn-success m-2 btn-sm w-lg"
          type="submit"
          disabled={amountValidation || loading}
        >
          {t("Continue")}
        </Button>
      </div>

    </AvForm>
  );
}
