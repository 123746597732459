import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createWalletTransfer,
  fetchWalletTransferStart,
  // createGoldTransfer,
  // fetchConversionRateStart,
  fetchWallets,
  getAccountTypesStart,
  getAccountsStart,
  updateType,
} from "store/actions";
import Loader from "components/Common/Loader";
import { fetchConversionRateStart } from "store/general/conversionRates/actions";

export default function TransferModal({ isOpen, toggle }) {

  const [from, setFrom] = React.useState(undefined);
  const [to, setTo] = React.useState(undefined);
  const [differentCurrency, setDifferentCurrency] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [notAllowed, setNotAllowed] = useState(false);
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { wallets, loading: walletLoading, clearingCounter, transferLoading } = useSelector((state) => state?.walletReducer);
  const { accounts, loading: accountLoading } = useSelector((state) => state.forex?.accounts);
  const { conversionRate = 1, loading: conversionLoading } = useSelector((state) => state?.conversionReducer);
  const  requests = useSelector((state) => state.walletReducer.requests);
  const totalDeposits = requests?.reduce((sum, transaction) => sum + transaction.baseAmount, 0);
  const { accountTypes } = useSelector((state) => state.forex.accounts);
  const standardB5AccountType = accountTypes?.filter((type) => type?.currencies?.[0]?.groupPath === "real\\usdINZ-B5" && type.type === "LIVE");
  const standardB5AccountTypeId = standardB5AccountType?.[0]?._id;
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(getAccountTypesStart());
  }, []);

  useEffect(() => {
    dispatch(getAccountsStart({
      type: "LIVE",
      limit: 100
    }));
  }, []);

  useEffect(() => {
    dispatch(fetchWallets({
      isDemo: false,
      limit: 100
    }));
  }, []);

  useEffect(() => {
    if (from !== undefined && to !== undefined) {
      if (from.currency !== to.currency) {
        setDifferentCurrency(true);
      } else {
        setDifferentCurrency(false);
      }
    }
    if (from === undefined || to === undefined) {
      setDifferentCurrency(false);
    }
  }, [from, to]);

  useEffect(() => {
    if (isOpen && transferLoading) {
      toggle();
    }
  }, [clearingCounter, transferLoading]);

  useEffect(() => {
    if (differentCurrency) {
      dispatch(fetchConversionRateStart({
        from: from.currency,
        to: to.currency
      }));
    }
  }, [differentCurrency, from, to]);


  const handleOnSelect = (selection, field) => {
    if (field === "from") {
      setFrom(selection);
    } else {
      setTo(selection);
    }
  };

  useEffect(() => {
    if (from?.isWallet === false && to?.isWallet === false) {
      setNotAllowed(true);
    } else {
      setNotAllowed(false);
    }

  }, [from, to]);

  useEffect(() => {
    if (amount < to?.accountMinDeposit) {
      setErrorMessage(`${t("Minimum Deposit For This Account is")} ${to?.accountMinDeposit} ${to?.currency}`);
    } else if (amount < from?.accountMinWithdraw) {
      setErrorMessage(`${t("Minimum Withdrawal For This Account is")} ${from?.accountMinWithdraw} ${from?.currency}`);
    }
     else {
      setErrorMessage("");
    }
  }, [amount]);

  useEffect(() => {
    if (to && to?.isWallet === false) {
      dispatch(fetchWalletTransferStart({
        page: 1,
        limit: 100,
        filteredValues : {
          tradingAccountTo: to?.id
        }
      }));

    } else if (from && from?.isWallet === false) {
      dispatch(fetchWalletTransferStart({
        page: 1,
        limit: 100,
        filteredValues : {
          tradingAccountFrom: from?.id,
        }
      }));
    }
  }, [to, from]);


  const getFromOptions = () => {
    const options = [];
    if (wallets.length > 0) {
      const filteredWallets = wallets.filter((wallet) => wallet?._id !== to?.id && !wallet.isInventory && !wallet.isCrypto);
      filteredWallets.forEach((wallet) => {
        options.push({
          label: `WALLET | ${wallet.asset} - ${wallet.amount?.toFixed(2)}`,
          value: {
            isWallet: true,
            id: wallet._id,
            currency: wallet?.isIb ? "USD" : wallet.asset,
            amount: wallet.amount,
          }
        });
      });
    }
    if (accounts !== null && accounts.length > 0) {
      const filteredAccounts = accounts.filter((account) => account._id !== to?.id && account.type === "LIVE"  && (to?.platform !== account.platform || from.isWallet));
      filteredAccounts.forEach((account) => {
        const amount = account.Balance 
                        ? account.Balance 
                        : account.balance;
        options.push({
          label: `${account?.platform} | ${account?.login} | ${account?.accountTypeId?.title} ${account?.accountTypeId?.type} - ${account.currency} - ${amount}`,
          value: {
            isWallet: false,
            platform: account.platform,
            id: account._id,
            currency: account.currency,
            amount : account.Balance,
            accountTypeTitle: account?.accountTypeId?.title,
            accountGroup: account?.accountTypeId?.currencies?.[0]?.groupPath,
            accountMinWithdraw: account?.accountTypeId?.minWithdrawal,

          }
        });
      });
    }
    return options;
  };


  const getToOptions = () => {
    if (from === undefined) return [];
    const options = [];
    const isWallet = (wallets && wallets.length > 0 && wallets.some((element)=>{
      return (element._id === from.id);
    }));
    const isFx = (accounts && accounts.length > 0 && accounts.some((element)=>{
      return (element._id === from.id);
    }));


    //if from = wallet show fx accounts
    if (isWallet){
      const filteredAccounts = accounts.filter((account) => account._id !== from?.id && account.type === "LIVE" && (from?.platform !== account.platform || from.isWallet));
      filteredAccounts.forEach((account) => {
        options.push({
          label: `${account?.login} | ${account?.accountTypeId?.title} ${account?.accountTypeId?.type} - ${account.currency} - ${account.Balance ?? account.balance}`,
          value: {
            isWallet: false,
            id: account._id,
            currency: account.currency,
            accountTypeTitle: account?.accountTypeId?.title,
            amount:  account.Balance ?? account.balance,
            login: account?.login,
            accountGroup: account?.accountTypeId?.currencies?.[0]?.groupPath,
            accountMinDeposit: account?.accountTypeId?.minDeposit,
          }
        });
      });
    }
    //if from = fx show wallets
    if (isFx){
      const filteredWallets = wallets.filter((wallet) => wallet._id !== from.id && !wallet.isInventory && !wallet.isCrypto);
      filteredWallets.forEach((wallet) => {
        options.push({
          label: `WALLET | ${wallet.asset} - ${wallet.amount?.toFixed(2)}`,
          value: {
            isWallet: true,
            id: wallet._id,
            currency: wallet?.isIb ? "USD" : wallet.asset,
            amount: wallet.amount,
          }
        });
      });
    }
    return options;
  };
  
  const handleSubmit = (e, values) => {
    const data = {
      fromId: values.from.id,
      toId: values.to.id,
      baseCurrency: from.currency,
      targetCurrency: to.currency,
      amount: values.amount,
      note: values.note,
    };
    if (from.isWallet && to.isWallet) {
      data.source = "WALLET";
      data.destination = "WALLET";
    } else if (!from.isWallet && !to.isWallet) {
      data.source = "FX";
      data.destination = "FX";
    } else if (from.isWallet && !to.isWallet) {
      data.source = "WALLET";
      data.destination = "FX";
      if (to.accountGroup === "real\\usdINZ-B1") {
        to.accountGroup = "real\\usdINZ-B5";
        dispatch(updateType({
         login: to.login,
         accountTypeId: standardB5AccountTypeId,
       }));
      }
      if ((totalDeposits + parseFloat(values.amount) ) < 20000 && to.accountGroup === "real\\usdINZ-B5") {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount) + (values.amount * 0.30);
      } else if ((totalDeposits + parseFloat(values.amount)) >= 20000 && totalDeposits <= 20000 && to.accountGroup === "real\\usdINZ-B5") {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount) + ((20000 - totalDeposits) * 0.30);
      } else {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount);
      }
    } else if (!from.isWallet && to.isWallet) {
      data.source = "FX";
      data.destination = "WALLET";
      if ((totalDeposits + parseFloat(values.amount) ) < 20000 && from.accountGroup === "real\\usdINZ-B5") {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount) - (values.amount * 0.30);
      } else if ((totalDeposits + parseFloat(values.amount)) >= 20000 && totalDeposits <= 20000 && from.accountGroup === "real\\usdINZ-B5") {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount) - ((20000 - totalDeposits) * 0.30);

      } else {
        data.baseAmount = parseFloat(values.amount);
        data.amount = parseFloat(values.amount);
      }
    }
    dispatch(createWalletTransfer(data));
  };
  return (
    <Modal centered isOpen={isOpen} toggle={toggle} >
      <ModalHeader className="d-flex flex-column gap-3" toggle={toggle}>
        {t("Internal Transfer")}
      </ModalHeader>
      {
        (walletLoading || accountLoading) ? <Loader/> : (
          <ModalBody  style={{
            margin: "1rem"
          }}>
            <AvForm onValidSubmit={(e, v) => handleSubmit(e, v)} >
              <div className="mb-3">
                <AvFieldSelecvt
                  name="from"
                  label={t("From")}
                  placeholder={t("Select")}
                  options={getFromOptions()}
                  value={from}
                  onChange={(e) => handleOnSelect(e, "from")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "From is required"
                    },
                  }}
                />
              </div>
              <div className="mb-3">
                <AvFieldSelecvt
                  name="to"
                  label={t("To")}
                  placeholder={t("Select")}
                  value={to}
                  options={getToOptions()}
                  onChange={(e) => handleOnSelect(e, "to")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "To is required"
                    },
                  }}
                />
              </div>
              {
                differentCurrency && (
                  conversionLoading ? <Loader/> : (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t("You have selected different currencies. Conversion rate will be applied.")}
                        {` 1 ${from.currency} = ${parseFloat((conversionRate))?.toFixed(2)} ${to.currency}`}
                      </Alert>
                    </div>
                  )
                )
              }
              {
                notAllowed && (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t("Internal Transfer Is Not Allowed between Trading Accounts")}
                      </Alert>
                    </div>
                  )
              }
              <div className="mb-3">
                <AvField
                  name="amount"
                  label={from ? `${t("Amount")} (${from.currency})` : t("Amount")}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  style={{
                    direction: currentLanguage === "ar" ? "rtl" : "ltr",
                    textAlign: currentLanguage === "ar" ? "right" : "left"
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: t("Amount is required")
                    },
                    min: {
                      value: 1,
                      errorMessage: t("Amount must be greater than 0")
                    },
                    max: {
                      value: from?.amount,
                      errorMessage: t("You don't have enough balance. Your balance is ") + `${from?.amount} ${from?.currency}`
                    }
                  }}
                />
              </div>
              {
                errorMessage && (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t(`${errorMessage}`)}
                      </Alert>
                    </div>
                  )
              }
              <div className="mb-3">
                <AvField
                  name="note"
                  label={t("Note")}
                  type="text"
                />
              </div>
              <div className="mb-3">
                {
                  (amount > 0 && to && from) && (
                    <Alert className="text-center">
                     {
                       !differentCurrency
                         ? `${t("Total Credit Amount")}: ${amount} ${from?.currency}`
                         : `${t("Total Credit Amount")}: ${(amount * conversionRate)?.toFixed(3)} ${to?.currency}`
                     }
                    </Alert>
                  )
                }
              </div>
              <div className="mb-3 text-center">
                {
                  conversionLoading ? <Loader/> : (
                    <Button disabled={notAllowed || errorMessage} className="color-bg-btn border-0 w-75" type="submit">
                      {t("Submit")}
                    </Button>
                  )
                }
              </div>
            </AvForm>
          </ModalBody>
        )
      }
    </Modal>
  );
}
