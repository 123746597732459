import Select from "react-select";
import React, { useEffect, useState } from "react";

const praxisPayments = [
    {
        name:"PayMaxisAPM - Inzo",
        hash: "7a85f2d7a804d7786e8beed70ab236a7",
        processer: "Directa24REST",
        method: "Banco do Brasil"
    },
    {
        name:"Brightcart - Inzo",
        hash: "ba16819a31988e5deaf19ce4000ac9fb",
        processer: "Brightcart",
        method: "Credit Card"
    },
    {
        name:"VoucherryAPM - Inzo",
        hash: "67a93633f90e2a4ac57cca25217c73e7",
        processer: "VoucherryAPM",
        method: "Voucherry"
    },
    {
        name:"AsiaBanks",
        hash: "d930bfdc029738c9ec385027e38726ab",
        processer: "AsiaBanks",
        method: "Online Banking APM"
    },
    {
        name:"Coinspaid - Alphapo Crypto",
        hash: "1c41a66c3f0edf95f762e281dec985a6",
        processer: "Coinspaid",
        method: "altbankwire2"
    },
    {
        name:"PayMaxisAPM - Crypto - Inzo",
        hash: "7b672367fb9a1408c88946725ca7ec09",
        processer: "PayMaxisAPM",
        method: "PayMaxisAPM"
    },
    {
        name:"PayMaxis - Inzo",
        hash: "8bc363a423108b9b44907cfa77b98e17",
        processer: "PayMaxis",
        method: "Credit Card"
    },
    {
        name:"PayMaxisAPM - Crypto - Inzo",
        hash: "7b672367fb9a1408c88946725ca7ec09",
        processer: "PayMaxisAPM",
        method: "PayMaxisAPM"
    },
    {
        name:"B2Broker - Inzo",
        hash: "e72050ea6497214e16f183ae1b4d3ead",
        processer: "B2Broker",
        method: "altbankwire2"
    },
    {
        name:"PayMaxisAPM - Inzo",
        hash: "7a85f2d7a804d7786e8beed70ab236a7",
        processer: "PayMaxisAPM",
        method: "AstropayOneTouch"
    },
    {
        name:"PayMaxisAPM - Inzo",
        hash: "7a85f2d7a804d7786e8beed70ab236a7",
        processer: "PayMaxisAPM",
        method: "AstropayOneTouch"
    }
];

function Praxis({ t, setIsFirstStepValid, setPaymentPayload, praxisName }) {
    const [praxisData, setPraxisData] = useState(null);
    useEffect(() => {
        // if (praxisData) {
        //     setPaymentPayload(praxisData);
        //     setIsFirstStepValid(true);
        // }
        const data = praxisPayments.find((p)=>p.name === praxisName);
        setPaymentPayload(data);
        setIsFirstStepValid(true);
      }, []);

    return (
      <div className="my-3">
        {/* <Select
          name="praxis"
          onChange={(e) => setPraxisData(e.value)}
          required
          placeholder={t("Payment Method")}
          options={praxisPayments.map((p) => {
            return {
              label: p.name,
              value: p
            };
          })}
        >
        </Select> */}
      </div>
    );
}

export default Praxis;