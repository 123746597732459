
export const withdrawalConfig = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: {
      wallet: true,
      mt5: true,
    },
    config: {},
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: {
      wallet: true,
      mt5: true,
    },
    config: {
      methods: [
        {
          network: "TR20",
          coins: ["USDT"]
        }
      ]
    },
  },
  {
    gateway: "صناديق-الاعتماد",
    name: "صناديق الاعتماد",
    image: "/img/payment-method/a3tmad.jpg",
    allowed: {
      wallet: true,
      mt5: true,
    },
    config: {
      methods: [
        {
          title: "حواله",
          fields: [
            {
              title: "receipt",
              type: "file",
              required: true,
            },
            {
              title: "جهة الحوالة",
              type: "text",
              required: true,
            },
          ]
        }, {
          title: "اعتماد",
          fields: [
            {
              title: "اسم الصندوق",
              type: "text",
              required: true,
            },
            {
              title: "رقم الصندوق",
              type: "text",
              required: true,
            },
          ],
        }
      ]
    }
  },
];