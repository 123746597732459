import { getCompetitions } from "apis/competitions";
import CardWrapper from "components/Common/CardWrapper";
import { useEffect, useState } from "react";
import {
    Button,
    CardHeader, Container, Table
} from "reactstrap";
import { showErrorNotification } from "store/actions";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
    Th,
    Thead,
    Tr
} from "react-super-responsive-table";
import Loader from "components/Common/Loader";
import moment from "moment";
import { withTranslation } from "react-i18next";
import SubscriptionModal from "./SubscriptionModal";


function Competitions(props) {
    const [competitions, setCompetitions] = useState([]);
    const [selectedComp, setSelectedComp] = useState(null);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const res = await getCompetitions();
            if (res && res.status) {
                setCompetitions(res.result);
            } else {
                dispatch(showErrorNotification("Cannot get competitions"));
            }
        })();
    }, []);

    const columns = [
        {
            dateField: "firstName",
            text: props.t("First Name"),
        },
        {
            dateField: "lastName",
            text: props.t("Last Name"),
        },
        {
            dateField: "totalAmount",
            text: props.t("Total Amount"),
            formatter: (val) => val.totalAmount ? parseFloat(val.totalAmount).toFixed(2) : "--",
        },
    ];

    return (<>
        <div className="page-content">
            <Container className="mt-5">
                <CardWrapper className="mt-5">
                    <CardHeader title="Competitions"></CardHeader>
                    <div className="mt-3">
                        <Select
                          options={competitions.map((comp) => {
                                return {
                                    label: `${comp.name} ${comp.clientType}`,
                                    value: comp
                                };
                            })}
                          onChange={(val) => {
                                setSelectedComp(val.value);
                            }}
                        >
                        </Select>
                        {selectedComp ? !selectedComp.isWinner ? <div className="mt-4">
                            <Table borderless responsive className="text-center mb-0" >
                                <Thead className="text-center table-light">
                                    <Tr>
                                        {columns.map((column, index) => (
                                            <Th data-priority={index} key={index} className="color-primary">
                                                {column.text}
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <tbody>
                                    {(
                                        selectedComp?.competitionStats?.map((item, index) => (
                                            <Tr key={index}>
                                                {columns.map((column, index) => (
                                                    <td key={index} data-priority={index}>
                                                        {column.formatter ? column.formatter(item) : item[column.dateField]}
                                                    </td>
                                                ))}
                                            </Tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <div className="mt-4">
                                <img src={selectedComp.image} style={{
                                    width: "100%",
                                }}>
                                </img>
                            </div>
                            <h5 className="text-center mt-4">
                                {selectedComp.cpText}
                            </h5>
                            {selectedComp.clientType === "LIVE" && selectedComp.status !== "COMPLETED" && <div className="d-flex align-items-center justify-content-center mt-4">
                                <Button onClick={() => {
                                    setOpenSubscriptionModal(true);
                                }}>{props.t("Subscribe")}</Button>
                            </div>}
                        </div> : <div className="mt-4">
                            <h2 className="text-center color-green">{props.t("Congratulations")}</h2>
                            <h6 className="text-center m-4">you won the competiton with {selectedComp.isWinner.reward.amount} USD as {selectedComp.isWinner.reward.type}</h6>
                        </div> : <></>}
                    </div>
                </CardWrapper>
                <SubscriptionModal
                  open={openSubscriptionModal}
                  onClose={() => {
                        setOpenSubscriptionModal(false);
                    }}
                  t={props.t}
                  comp={selectedComp}
                ></SubscriptionModal>
            </Container>
        </div>
    </>);
}

export default withTranslation()(Competitions);