import {
  companyName,
  privacyPolicyLink,
  clientAgreementLink,
  companyWebsite,
} from "./content";

export const REGISTER = `By clicking here I give my consent for ${companyName} to contact me for marketing purposes. You can opt out at any time. For further details please see our Marketing and Communication Policy Statement.`;

export const CLIENT_AGREEMENT = `You have read, understood, and agreed to ${companyName}'s client agreement which includes order execution policy, conflict of interest policy, <a href=${privacyPolicyLink} target='_blank'>privacy policy</a>, 3rd party disclosure policy and any other terms in the <a href=${clientAgreementLink} target='_blank'>client agreement</a>.`;

export const CLIENT_AGREEMENT_AR = `لقد قرأت، وفهمت، ووافقت على اتفاقية العميل لـ ${companyName} والتي تتضمن سياسة تنفيذ الأوامر، وسياسة تعارض المصالح، <a href=${privacyPolicyLink} target='_blank'>سياسة الخصوصية</a>، سياسة الإفصاح لأطراف ثالثة، وأي شروط أخرى في <a href=${clientAgreementLink} target='_blank'>اتفاقية العميل</a>.`;

export const IB_AGREEMENT = `You have read, understood, acknowledged, and agreed to all ${companyName}'s policies, terms & conditions and client agreements which are available on the company's <a href=${companyWebsite} target='_blank'>website</a>`;

export const IB_AGREEMENT_AR =  `لقد قرأت، وفهمت، واعترفت، ووافقت على جميع سياسات ${companyName} وشروطها واتفاقيات العملاء المتاحة على موقع الشركة <a href=${companyWebsite} target='_blank'>على الويب</a>`;

export const COUNTRY_REGULATIONS = `You confirm that you do not breach any regulation of your country of residence in trading with ${companyName}.`;

export const E_SIGNATURE = "Your electronic signature is considered a legal and official signature.";
