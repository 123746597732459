import React, {
  useEffect,
  useState
} from "react";
import {
  Button,
  Modal,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  getUserPin,
  sendEmailPinAPI, sendEmailPinAPILogin, verifyEmailPinAPI
} from "apis/register";
import { useDispatch } from "react-redux";
import { loginUser } from "store/actions";

  
function OTP({ isOpen, toggleOpen, ...props }) {
    const {emailPin, password, history} = props;
    const { t } = useTranslation();
    const [havePin, setHavePin] = useState(false);
    const [verifingPin, setVerifingPin] = useState(false);
    const [verifiedPin, setVerifiedPin] = useState(false);
    const [pinBtnText, setPinBtnText] = useState(t(""));
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [openInput, setOpenBtn] = useState(true);
    const [otpCode, setOtp] = useState("");
    const [pinSent, setPinSent] = useState(false);
    const dispatch = useDispatch();

    const sendPin = async () => {
      try {
        setPinBtnText(t("Sending"));
        setHavePin(true);
        const res = await sendEmailPinAPILogin({
          email: emailPin?.toLowerCase(),
          isRegister: false
        });
        if (res.status) {
          setHavePin(true);
          setOpenBtn(true);
          setPinSent(true);
          setDisabledBtn(true);
          setPinBtnText(t("Enter PIN"));
  
        } else {
          setHavePin(false);
          setDisabledBtn(true);
          setPinSent(true);
          setPinBtnText(res);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const verifyEmailPin = async () => {
      setVerifingPin(true);
      setPinBtnText(t("Verifying"));
      const resp = await verifyEmailPinAPI({
        email: emailPin,
        emailPin: otpCode,
  
      });
      if (resp.status) {
        setVerifingPin(false);
        setVerifiedPin(true);
        setDisabledBtn(false);
        setPinBtnText(t("PIN Verified"));
      } else {
        setDisabledBtn(true);
        setPinSent(true);
        setVerifingPin(false);
        setVerifiedPin(false);
        setPinBtnText(t("Invalid PIN"));
      }
    };

    const getUser = async (email) => {
      try {
        const res = await getUserPin({ email: email });
        if (res?.result?.settings?.twoFactorAuthEnabled === true) {
          setDisabledBtn(false);
          setOpenBtn(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (emailPin?.trim() !== "") {
          getUser(emailPin?.toLowerCase());
          // console.log("OTP Before", otpCode);
          setOtp("");
          // console.log("OTP After", otpCode);
          setDisabledBtn(false);
          setPinSent(false);
        }
      });
  
      // Clear the timeout if the component unmounts or if the dependencies change
      return () => clearTimeout(timeoutId);
  
    }, [emailPin]);


    useEffect(() => {
      
      if (otpCode === "") {
        setDisabledBtn(false);
      } else {
        verifyEmailPin();
      }
  
    }, [otpCode]);

    // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    history.push("/dashboard");
  };
  

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleOpen}
      centered={true}
      size="lg"
      className='custom-modal'
    >
        <AvForm 
          onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}>
        <div style={{
              display: "flex",
              width: "80%",
              justifyContent: "space-between",
              alignItems: "center",
              height: "150px",
              margin: "auto",
              
            }}  className="  d-lg-flex d-xl-flex d-sm-block d-flex-block mt-sm-4 d-block gx-3">
            <div className="mb-3" >
              <AvField
                name="Opt"
                label={props.t("Add OTP")}
                className="form-control"
                placeholder={props.t("Add OTP to Verify")}
                type="number"
                errorMessage={props.t("Enter valid OTP")}
                validate={{
                  required: {
                    value: true,
                  },
                }}
                value={otpCode}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "20%"
            }} className=" gap-sm-3">
            <div >
              <button
                className="btn btn-primary w-100 waves-effect waves-light"
                type="button"
                disabled={pinSent || !emailPin ? true : false}
                onClick={() => sendPin()}
              >
                Send
              </button>
            </div>
            <div>
              <button
                className="btn btn-primary w-100 waves-effect waves-light"
                type="submit"
                disabled={!verifiedPin}
              >
                Login
              </button>
            </div>

            </div>

            </div>
            <p style={{
              margin: "0 auto 25px",
              width: "80%",
              color: verifiedPin ? "green" : "red"
            }}>{pinBtnText}</p>

    </AvForm>

    </Modal>
    
  );
}
export default withTranslation()(OTP);