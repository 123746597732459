import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tr, Td } from "react-super-responsive-table";

const Referrals = ({ data }) => {
  const { t } = useTranslation();
  const [showNested, setShowNested] = useState(false);
  const toggleNested = (name) => {
    setShowNested({
      ...showNested,
      [name]: !showNested[name]
    });
  };


  return (data ?
    data.map((parent) =>
      <React.Fragment key={parent._id}>
        <Tr>
          <Td className="py-2">
            {parent.childs &&
              <Link to="#"
                className="mdi mdi-chevron-down me-2"
                onClick={() => toggleNested(parent._id)}
              ></Link>
            }
            {parent?.firstName} {parent?.lastName}
          </Td>
          <Td className="py-2">{parent.fx.isIb ? t("Sub IB") : t("Client")}</Td>
          {/* acc num */}
          <Td className="py-2">
            {parent.login}
          </Td>
          <Td className="py-2">{data.firstName} {data.lastName}</Td>
        </Tr>
      </React.Fragment>
    )
    : <Tr><Td colSpan="4">{t("No referrals found.")}</Td></Tr>
  );
};

export default Referrals;