module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 1,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name: "Bitcoin",
      networks: [
        {
          name: "Bitcoin",
          address: "1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX",
          tag: "bitcoin",
        },
      ],
    },
  ],
  ENABLE_DARK_MODE: true,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100],
  PLATFORMS: [
    {
      name: "Desktop Trader - CTrader",
      logo: "https://marketing.spotware.com/download/user/data/logos/ctrader_round.png",
      image: "https://www.icmarkets.com/assets/images/about-ctrader.webp",
      isLocalImg: false,
      downloadLink: "https://getctrader.com/inzo/ctrader-inzo-setup.exe",
    },
    {
      name: "Android Trader - CTrader",
      logo: "https://marketing.spotware.com/download/user/data/logos/ctrader_round.png",
      image:
        "https://fusionmarkets.com/sites/644299a5071d1a053eaf15af/assets/64c1177a071d1a053eaf281b/ctrader-mobile-image.png",
      isLocalImg: false,
      downloadLink: "https://play.google.com/store/apps/details?id=com.inzo.app&hl=en&gl=US",
    },
    {
      name: "IOS Trader - CTrader",
      logo: "https://marketing.spotware.com/download/user/data/logos/ctrader_round.png",
      image: "https://www.financemagnates.com/wp-content/uploads/2017/10/ctrader3.jpg",
      isLocalImg: false,
      downloadLink: "#",
    },
    {
      name: "Desktop Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/web/inzo.llc/mt5/inzo5setup.exe",
    },
    {
      name: "Android Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
      isLocalImg: false,
      downloadLink: "https://download.metatrader.com/cdn/mobile/mt5/android?server=Inzo-Live",
    },
    {
      name: "IOS Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
      isLocalImg: false,
      downloadLink: "https://download.metatrader.com/cdn/mobile/mt5/ios?server=Inzo-Live",
    },
  ],
};
